import React from 'react'

import { HeaderItems } from '~/utils/getHeaderItems'

import { Button } from '~/components/Button/Button'

// import { gt } from '~/lib/gt'
import styles from './AuthButtons.module.css'

export function AuthButtons({ buttonsTranslations }: { buttonsTranslations: HeaderItems['buttons'] }) {
  return (
    <div className={styles.authButtonsContainer}>
      <Button href='/user' variant='ghost' className={styles.signButton} withArrow={false} size='small'>
        {buttonsTranslations.signIn}
        {/* {gt.tp('Header', 'Sign in')} */}
      </Button>
      <Button href='/user' className={styles.signButton} withArrow={false} size='small'>
        {buttonsTranslations.signUp}
        {/* {gt.tp('Header', 'Sign up')} */}
      </Button>
    </div>
  )
}
